@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap");

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d4d4d3;
}

* {
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
}

.mobileNav {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out; /* Adjust duration and easing as needed */
}

.mobileNav.openMobileNav {
  max-height: 500px; /* Adjust the maximum height as needed */
}

.hexagon {
  width: 50px;
  height: 25px;
  background: #0068e5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hexagon::before {
  content: "";
  position: absolute;
  top: -13px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 13px solid #0068e5;
}
.hexagon::after {
  content: "";
  position: absolute;
  bottom: -13px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 13px solid #0068e5;
}
